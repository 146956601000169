import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import QRCode from 'react-qr-code';
import { Centered } from '../components/Centered';
import bg from './bg.jpeg';
import logo from './logo.svg';

export function LinkPage() {
  const location = window.location.href;

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100vh',
          background: `url(${bg})`,
          backgroundSize: 'cover'
        }}
      >
        <Centered>
          <Card>
            <CardContent sx={{ flex: '1 0 auto', p: 6 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2
                }}
              >
                <Box sx={{ mr: '13px' }}>
                  <img src={logo} alt="logo" />
                </Box>
                <Typography
                  variant="h5"
                  color="#333333"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 600
                  }}
                >
                  Roxhill Voice Notes - Travel
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography
                  textAlign="center"
                  variant="body2"
                  color="#333333"
                  sx={{
                    fontSize: '16px',
                    fontFamily: "'Mulish', 'sans-serif'"
                  }}
                >
                  You need to open this link from your phone.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  textAlign="center"
                  variant="body2"
                  color="#333333"
                  sx={{
                    fontSize: '16px',
                    fontFamily: "'Mulish', 'sans-serif'"
                  }}
                >
                  Go to your phone’s email client or scan this QR code from your
                  <br /> phone to open the app.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Box
                  mb={2}
                  sx={{
                    border: '1px solid #ddd',
                    p: 2,
                    borderRadius: '5px'
                  }}
                >
                  <QRCode size={116} value={location} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Centered>
      </Box>
    </>
  );
}
