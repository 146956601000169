import { Centered } from '@everlutionsk/ui';
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { LandingPage } from './LandingPage';
import { LinkPage } from './LinkPage';

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route
          path="auth/invitation/:token"
          element={isMobile ? <Navigate to="/" /> : <LinkPage />}
        />
        <Route
          path="auth/reset-password-new/:temporaryPassword"
          element={isMobile ? <Navigate to="/" /> : <LinkPage />}
        />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

function PageNotFound() {
  return (
    <Centered>
      <Typography variant="h3">Page not found!</Typography>
    </Centered>
  );
}
