import { styled } from '@mui/material';

export const Centered = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '@media (max-width: 450px)': {
    position: 'relative'
  }
}));
