import { Box, Card, CardContent, CardMedia, Grid, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Centered } from '../components/Centered';
import appStore from './appStore.png';
import firstSvg from './first.svg';
import googlePlay from './googlePlay.png';
import roxhill from './image.png';
import logo from './logo.svg';
import secondSvg from './second.svg';
import thirdSvg from './third.svg';
import QRCode from 'react-qr-code';

export function LandingPage() {
  return (
    <Box sx={{ backgroundColor: 'black', minHeight: '100vh', height: '100%' }}>
      <Centered>
        <Box sx={{ maxWidth: '1380px' }}>
          <Card>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CardContent sx={{ flex: '1 0 auto', px: 6 }}>
                  <Box my={3} display="flex" alignItems="center">
                    <Box sx={{ mr: '10px' }}>
                      <img src={logo} />
                    </Box>
                    <Typography
                      variant="h5"
                      color="#333333"
                      sx={{
                        fontSize: '20px',
                        fontWeight: 600
                      }}
                    >
                      Roxhill Voice Notes - Travel
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography
                      color="#333333"
                      sx={{
                        fontSize: '30px',
                        fontWeight: 700,
                        lineHeight: '30px',
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      Voice Notes by Roxhill Media
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography
                      variant="subtitle1"
                      color="#333333"
                      sx={{
                        fontSize: '24px',
                        fontFamily: "'Mulish', 'sans-serif'",
                        fontWeight: 500
                      }}
                    >
                      For Travel Journalist and PRs
                    </Typography>
                  </Box>

                  <Box mb={1}>
                    <Typography
                      variant="body2"
                      color="#333333"
                      sx={{
                        fontSize: '16px',
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      Welcome to Roxhill’s Voice Notes – Travel. This platform is for busy travel
                      journalists & PRs to exchange requests and pitches, with all communications
                      being done via Voice Notes.
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography
                      variant="body2"
                      color="#333333"
                      sx={{
                        fontSize: '16px',
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      It’s ideal for journalists to use when researching a story, discovering news
                      stories in a particular destination and tracking down relevant PRs for
                      particular locations. Rather than send an email to travel PRs, all
                      communications are done via Voice Notes.
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography
                      variant="body2"
                      color="#333333"
                      sx={{
                        fontSize: '16px',
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      It has been designed with a journalist’s busy life in mind appreciating the
                      unrelenting nature of their inbox. Voice Notes can be delivered at all times
                      and will be listened to by a plethora of travel PRs. No other journalists will
                      hear your Voice Notes.
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography
                      variant="body2"
                      color="#333333"
                      sx={{
                        fontSize: '16px',
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      If you want to learn more about this platform, then please download the app
                      and Request to Join or email us at{' '}
                      <span style={{ color: '#096060' }}>
                        <a
                          style={{
                            textDecoration: 'none',
                            color: 'rgb(9, 96, 96)'
                          }}
                          href="mailto:vn@roxhilmedia.com"
                        >
                          vn@roxhilmedia.com
                        </a>
                        .
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex'
                    }}
                    justifyContent={{
                      xs: 'center',
                      sm: 'unset'
                    }}
                  >
                    <Box
                      mr={3}
                      sx={{
                        border: '1px solid #ddd',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px'
                      }}
                    >
                      <Box mb={2}>
                        <QRCode
                          size={116}
                          value={'https://apps.apple.com/us/app/roxhill-voice-notes/id1612056484'}
                        />
                      </Box>
                      <Link href="https://apps.apple.com/us/app/roxhill-voice-notes/id1612056484">
                        <img src={appStore} width="100%" />
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid #ddd',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px'
                      }}
                    >
                      <Box mb={2}>
                        <QRCode
                          size={116}
                          value={
                            'https://play.google.com/store/apps/details?id=com.everlution.voicenotes'
                          }
                        />
                      </Box>
                      <Link href="https://play.google.com/store/apps/details?id=com.everlution.voicenotes">
                        <img src={googlePlay} width="100%" />
                      </Link>
                    </Box>
                  </Box>
                </CardContent>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', pb: 1, px: 6 }}
                  flexDirection={{
                    xs: 'column',
                    sm: 'row'
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      variant="caption"
                      color="#096060"
                      sx={{
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      <Link href="https://roxhillmedia.com/terms-and-conditions/">
                        Terms & Conditions
                      </Link>
                    </Typography>
                  </Box>
                  <Box mr={1}>&bull;</Box>
                  <Box mr={1}>
                    <Typography
                      variant="caption"
                      color="#096060"
                      sx={{
                        fontFamily: "'Mulish', 'sans-serif'"
                      }}
                    >
                      <Link href="https://roxhillmedia.com/privacy-policy/">Privacy Policy</Link>
                    </Typography>
                  </Box>
                  <Box mr={1}>&bull;</Box>
                  <Typography
                    variant="caption"
                    color="#096060"
                    sx={{
                      fontFamily: "'Mulish', 'sans-serif'"
                    }}
                  >
                    <Link href="https://roxhillmedia.com/">Roxhill Media Website</Link>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={0} md={6}>
                <Box
                  display={{
                    xs: 'none',
                    sm: 'none',
                    md: 'flex',
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}
                >
                  <CardMedia component="img" image={roxhill} alt="Roxhill Travel Notes" />
                  <Box
                    sx={{
                      width: '400px',
                      overflow: 'hidden',
                      position: 'absolute',
                      top: '16%',
                      left: '22%'
                    }}
                  >
                    <Swip
                      pagination={{}}
                      slidesPerView={1}
                      spaceBetween={30}
                      autoplay
                      modules={[Pagination, Autoplay]}
                    >
                      <SwiperSlide>
                        <img src={firstSvg} />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={secondSvg} />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={thirdSvg} />
                      </SwiperSlide>
                    </Swip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Centered>
    </Box>
  );
}

const Swip = styled(Swiper)(() => ({
  ':root': { '--swiper-theme-color': '#007aff' },
  '.swiper': {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    padding: '0',
    zIndex: 1
  },
  '.swiper-vertical>.swiper-wrapper': { flexDirection: 'column' },
  '.swiper-wrapper': {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box'
  },
  '.swiper-android .swiper-slide,.swiper-wrapper': {
    transform: 'translate3d(0px,0,0)'
  },
  '.swiper-pointer-events': { touchAction: 'pan-y' },
  '.swiper-pointer-events.swiper-vertical': { touchAction: 'pan-x' },
  '.swiper-slide': {
    flexShrink: 0,
    width: '100%',
    height: '100%',
    position: 'relative',
    transitionProperty: 'transform',
    textAlign: 'center',
    fontSize: '18px',
    background: 'transparent',
    display: ['-webkit-box', '-ms-flexbox', '-webkit-flex', 'flex'],
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    WebkitJustifyContent: 'center',
    justifyContent: 'center',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    WebkitAlignItems: 'center',
    alignItems: 'center'
  },
  '.swiper-slide-invisible-blank': { visibility: 'hidden' },
  '.swiper-autoheight,.swiper-autoheight .swiper-slide': { height: 'auto' },
  '.swiper-autoheight .swiper-wrapper': {
    alignItems: 'flex-start',
    transitionProperty: 'transform,height'
  },
  '.swiper-backface-hidden .swiper-slide': {
    transform: 'translateZ(0)',
    WebkitBackfaceVisibility: 'hidden',
    backfaceVisibility: 'hidden'
  },
  '.swiper-3d,.swiper-3d.swiper-css-mode .swiper-wrapper': {
    perspective: '1200px'
  },
  '.swiper-3d .swiper-cube-shadow,.swiper-3d .swiper-slide,.swiper-3d .swiper-slide-shadow,.swiper-3d .swiper-slide-shadow-bottom,.swiper-3d .swiper-slide-shadow-left,.swiper-3d .swiper-slide-shadow-right,.swiper-3d .swiper-slide-shadow-top,.swiper-3d .swiper-wrapper':
    {
      transformStyle: 'preserve-3d'
    },
  '.swiper-3d .swiper-slide-shadow,.swiper-3d .swiper-slide-shadow-bottom,.swiper-3d .swiper-slide-shadow-left,.swiper-3d .swiper-slide-shadow-right,.swiper-3d .swiper-slide-shadow-top':
    {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      zIndex: 10
    },
  '.swiper-3d .swiper-slide-shadow': { background: 'rgba(0,0,0,.15)' },
  '.swiper-3d .swiper-slide-shadow-left': {
    backgroundImage: 'linear-gradient(to left,rgba(0,0,0,.5),rgba(0,0,0,0))'
  },
  '.swiper-3d .swiper-slide-shadow-right': {
    backgroundImage: 'linear-gradient(to right,rgba(0,0,0,.5),rgba(0,0,0,0))'
  },
  '.swiper-3d .swiper-slide-shadow-top': {
    backgroundImage: 'linear-gradient(to top,rgba(0,0,0,.5),rgba(0,0,0,0))'
  },
  '.swiper-3d .swiper-slide-shadow-bottom': {
    backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,.5),rgba(0,0,0,0))'
  },
  '.swiper-css-mode>.swiper-wrapper': {
    overflow: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none'
  },
  '.swiper-css-mode>.swiper-wrapper::-webkit-scrollbar': { display: 'none' },
  '.swiper-css-mode>.swiper-wrapper>.swiper-slide': {
    scrollSnapAlign: 'start start'
  },
  '.swiper-horizontal.swiper-css-mode>.swiper-wrapper': {
    scrollSnapType: 'x mandatory'
  },
  '.swiper-vertical.swiper-css-mode>.swiper-wrapper': {
    scrollSnapType: 'y mandatory'
  },
  '.swiper-centered>.swiper-wrapper::before': {
    content: "''",
    flexShrink: 0,
    order: 9999
  },

  '.swiper-centered.swiper-horizontal>.swiper-wrapper::before': {
    height: '100%',
    minHeight: '1px',
    width: 'var(--swiper-centered-offset-after)'
  },

  '.swiper-centered.swiper-vertical>.swiper-wrapper::before': {
    width: '100%',
    minWidth: '1px',
    height: 'var(--swiper-centered-offset-after)'
  },
  '.swiper-centered>.swiper-wrapper>.swiper-slide': {
    scrollSnapAlign: 'center center'
  },
  '.swiper-pagination': {
    position: 'absolute',
    textAlign: 'center',
    transition: '.3s opacity',
    transform: 'translate3d(0,0,0)',
    zIndex: 10
  },
  '.swiper-pagination.swiper-pagination-hidden': { opacity: 0 },
  '.swiper-pagination-disabled>.swiper-pagination,.swiper-pagination.swiper-pagination-disabled': {
    display: 'none !important'
  },
  '.swiper-horizontal>.swiper-pagination-bullets,.swiper-pagination-bullets.swiper-pagination-horizontal,.swiper-pagination-custom,.swiper-pagination-fraction':
    {
      bottom: '10px',
      left: '0',
      width: '100%'
    },
  '.swiper-pagination-bullets-dynamic': { overflow: 'hidden', fontSize: '0' },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet': {
    transform: 'scale(.33)',
    position: 'relative'
  },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active': {
    transform: 'scale(1)'
  },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main': {
    transform: 'scale(1)'
  },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev': {
    transform: 'scale(.66)'
  },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev': {
    transform: 'scale(.33)'
  },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next': {
    transform: 'scale(.66)'
  },
  '.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next': {
    transform: 'scale(.33)'
  },
  '.swiper-pagination-bullet': {
    width: 'var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px))',
    height: 'var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px))',
    display: 'inline-block',
    borderRadius: '50%',
    background: '#fff',
    opacity: 'var(--swiper-pagination-bullet-inactive-opacity, .2)'
  },
  'button.swiper-pagination-bullet': {
    border: 'none',
    margin: '0',
    padding: '0',
    boxShadow: 'none',
    WebkitAppearance: 'none',
    appearance: 'none'
  },
  '.swiper-pagination-clickable .swiper-pagination-bullet': {
    cursor: 'pointer'
  },
  '.swiper-pagination-bullet:only-child': { display: 'none !important' },
  '.swiper-pagination-bullet-active': {
    opacity: 'var(--swiper-pagination-bullet-opacity, 1)',
    background: '#fff'
  },
  '.swiper-pagination-vertical.swiper-pagination-bullets,.swiper-vertical>.swiper-pagination-bullets':
    {
      right: '10px',
      top: '50%',
      transform: 'translate3d(0px,-50%,0)'
    },
  '.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet':
    {
      margin: 'var(--swiper-pagination-bullet-vertical-gap,6px) 0',
      display: 'block'
    },
  '.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,.swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic':
    {
      top: '50%',
      transform: 'translateY(-50%)',
      width: '8px'
    },
  '.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,.swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet':
    {
      display: 'inline-block',
      transition: '.2s transform,.2s top'
    },
  '.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet':
    {
      margin: '0 var(--swiper-pagination-bullet-horizontal-gap,4px)'
    },
  '.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic':
    {
      left: '50%',
      transform: 'translateX(-50%)',
      whiteSpace: 'nowrap'
    },
  '.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet':
    {
      transition: '.2s transform,.2s left'
    },
  '.swiper-horizontal.swiper-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet': {
    transition: '.2s transform,.2s right'
  },
  '.swiper-pagination-progressbar': {
    background: 'rgba(0,0,0,.25)',
    position: 'absolute'
  },
  '.swiper-pagination-progressbar .swiper-pagination-progressbar-fill': {
    background: 'var(--swiper-pagination-color,var(--swiper-theme-color))',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    transform: 'scale(0)',
    transformOrigin: 'left top'
  },
  '.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill': {
    transformOrigin: 'right top'
  },
  '.swiper-horizontal>.swiper-pagination-progressbar,.swiper-pagination-progressbar.swiper-pagination-horizontal,.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,.swiper-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite':
    {
      width: '100%',
      height: '4px',
      left: '0',
      top: '0'
    },
  '.swiper-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite,.swiper-pagination-progressbar.swiper-pagination-vertical,.swiper-vertical>.swiper-pagination-progressbar':
    {
      width: '4px',
      height: '100%',
      left: '0',
      top: '0'
    },
  '.swiper-pagination-lock': { display: 'none' }
}));

function Link({ href, children }: { href: string; children: string | ReactNode }) {
  return (
    <a
      style={{
        textDecoration: 'none',
        color: 'rgb(9, 96, 96)'
      }}
      href={href}
      target="_blank"
      rel="noopener noreferrer external"
    >
      {children}
    </a>
  );
}
